import { apiPccourseDetail, apiCollect, apiDelCollect } from "@/api/api.js";
export default {
  components: {},
  data() {
    return {
      resultData: [],
      courseId: sessionStorage.getItem("tcl_course_id"),
      Vipisible: false
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getPccourseDetail();
    },
    getPccourseDetail() {
      let paramsBase = {
        course_id: this.courseId,
        lang: this.$i18n.locale
      };
      apiPccourseDetail(paramsBase).then(res => {
        this.resultData = res.data;
      });
    },
    clickAgainExercise(item) {
      if (item.is_vip == 0) {
        this.Vipisible = true;
      } else {
        sessionStorage.setItem(
          "tcl_pccourse-detail_title",
          this.resultData.title
        );
        sessionStorage.setItem("tcl_ucld_id", item.puid);
        this.$router.push({ name: "SkillExercise" });
      }
    },
    clickCollect(data) {
      let paramsBase = {};
      if (this.$i18n.locale == "ch") {
        paramsBase.type = "courses";
      } else {
        paramsBase.type = "en_courses";
      }
      if (data.is_collect == 1) {
        paramsBase.paramerid = data.id;
        apiDelCollect(paramsBase).then(res => {
          this.$layer.msg("已取消收藏");
          data.is_collect = 0;
        });
      } else {
        paramsBase.aid = data.id;

        apiCollect(paramsBase).then(res => {
          this.$layer.msg("收藏成功!");
          data.is_collect = 1;
        });
      }
    },
    clickRecharge() {
      this.$router.push({ name: "vip" });
    }
  }
};
